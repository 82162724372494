<template>
    <div class="civilServant_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.preparationsProvincialExamName" contText=""> </CourseTitle>
            <div class="main content display flex-wrap"
                :style="{ backgroundImage: `url(${formData.preparationsProvincialExamImgUrl})` }">
                <div class="list" v-for="(item, index) in formData.civilServantProvincialExams" :key="index">
                    <div class="list_name">{{ item.provincialExamName }}</div>
                    <div class="list_text">{{ item.provincialExamIntroduce }}</div>
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.examEmployName" contText=""> </CourseTitle>
            <div class="content  flex">
                <img class="icon_img" :src="formData.examEmployImgUrl" alt="">
                <div class="main  ">
                    <div class="list" v-for="(item, index) in formData.civilServantExamEmploys" :key="index">
                        {{ item.examEmployIntroduce }}
                    </div>

                </div>
            </div>
            <div class="btn display pointer" @click="service">立即进入</div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.examinationRequirementName" contText="" isWhite="1"> </CourseTitle>
            <div class="content flex">
                <div class="main flex">
                    <div class="list flex-center column"
                        v-for="(item, index) in formData.civilServantExaminationRequirements" :key="index">
                        <img class="list_img" :src="item.examinationRequirementImgUrl" alt="">
                        <div class="list_name">{{ item.examinationRequirementName }}</div>
                        <div class="list_text">{{ item.examinationRequirementIntroduce }}</div>
                        <div class="btn display pointer" @click="service">查看更多</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.informationDisclosureName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main display">
                    <div class="list flex-center column"
                        v-for="(item, index) in formData.civilServantInformationDisclosures" :key="index">
                        <img class="list_img" :src="item.informationDisclosureImgUrl" alt="">
                        <div class="list_name">{{ item.informationDisclosureName }}</div>
                        <div class="list_btn display pointer" @click="service">咨询获取</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.examinationMaterialName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main flex">
                    <div class="main_fl">
                        <ul class="flex">
                            <li class="ul_li " v-for="(item, index) in formData.civilServantExaminationMaterials"
                                :key="index">
                                <div class="label display">{{ item.examinationMaterialName }}</div>
                                <ul class="ul_li_ul">
                                    <li class="ul_li_ul_li display"
                                        v-for="(items, index) in item.civilServantExaminationMaterials" :key="index">
                                        {{ items.examinationMaterialName }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="main_fr">
                        <div class="title">{{ formData.oneClickClaimName }}</div>
                        <div class="formData">
                            <div class="list flex-center">
                                <img class="list_img" src="../../assets/img/civilServant/user.png" alt="">
                                <input type="text" v-model="params.userName" placeholder="请填写您的姓名">
                            </div>
                            <div class="list flex-center">
                                <img class="list_img" src="../../assets/img/civilServant/phone.png" alt="">
                                <input type="text" maxlength="11" v-model="params.phoneNumber" placeholder="请填写您的手机号">
                            </div>
                            <div class="list flex-center">
                                <img class="list_img" src="../../assets/img/civilServant/book.png" alt="">
                                <el-select v-model="params.typeQualification" placeholder="请选择您要申领的资料">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="btn display pointer" @click="submitBtn">立即申领备考资料</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.hotCourseName" :contText="formData.hotCourseIntroduce" isWhite="1">
            </CourseTitle>
            <div class="content ">
                <div class="main flex flex-wrap">
                    <img class="list_img" :src="formData.hotCourseImgUrl" alt="">
                    <div class="list " :class="index % 2 != 0 ? 'list_fr' : ''"
                        v-for="(item, index) in formData.civilServantHotCourses" :key="index">
                        <div class="listName display">{{ item.hotCourseName }}</div>
                        <div class="richText" v-html="item.hotCourseIntroduce"> </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.provincialTestSprintDataName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main ">
                    <div class="table_div flex">
                        <div class="list display" v-for="(item, index) in tableList" :key="index">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="table_div table_div_list flex" v-for="(item, index) in formData.civilServantSprintDatas"
                        :key="index">
                        <div class="list display">
                            {{ item.sprintDataSubject }}
                        </div>
                        <div class="list display">
                            {{ item.sprintDataDate }}
                        </div>
                        <div class="list display">
                            {{ item.sprintDataCourseContent }}
                        </div>
                        <div class="list display">
                            {{ item.sprintDataWayReceiving }}
                        </div>
                        <div class="list display">
                            {{ item.sprintDataTeacher }}
                        </div>
                        <div class="list display" v-html="item.sprintDataCharacteristic">
                        </div>

                    </div>
                </div>
                <div class="btn display pointer" @click="service">咨询获取</div>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.courseFeaturesName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main Between">
                    <div class="list" @mouseover="mouseOver(index)" @mouseleave="mouseLeave"
                        v-for="(item, index) in formData.civilServantCourseFeaturesS" :key="index">
                        <div class="head flex-center">
                            <img :src="activeIndex == index ? item.highlightCourseFeaturesImgUrl : item.courseFeaturesImgUrl"
                                alt="">
                            <div class="list_name">{{ item.courseFeaturesName }}</div>
                        </div>
                        <div class="list_text">{{ item.courseFeaturesIntroduce }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.supportingServiceName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main ">
                    <div class="list flex-center" v-for="(item, index) in formData.civilServantSupportingServices"
                        :key="index">
                        <div class="list_text">
                            {{ item.supportingServiceName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module10">
            <CourseTitle title="常见问题解答" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main Between flex-wrap">
                    <div class="list flex-center" v-for="(item, index) in formData.jobSalutationQuestions" :key="index">
                        <img src="../../assets/img/civilServant/problem.png" alt="">
                        <div class="list_text">
                            {{ item.questionName }}
                        </div>
                        <div class="btn display pointer" @click="service"> 立即咨询</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            activeIndex: null,
            list: [
                {},
                {},
                {},
            ],
            options: [{
                value: '行测备考',
                label: '行测备考'
            }, {
                value: '申论备考',
                label: '申论备考'
            }, {
                value: '面试备考',
                label: '面试备考'
            },
            ],
            value: '',
            tableList: [
                {
                    name: '科目',
                },
                {
                    name: '日期',
                },
                {
                    name: '课程内容',
                },
                {
                    name: '领取方式',
                },
                {
                    name: '师资',
                },
                {
                    name: '特色',
                },
            ],
            formData: {},
            params: {
                userName: '',
                phoneNumber: '',
                courseId: 1,
                courseType: 16,
                courseName: '公务员考试',
                email: '',
                companyName: '',
                questionContent: '',
                typeQualification: '',
                certificationProject: '',
                locationEnterprise: '',
                dateEstablishment: '',
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.api.civilServantApi().then(res => {
                this.formData = res.data
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        mouseOver(index) {
            console.log(index);
            this.activeIndex = index
        },
        mouseLeave(index) {
            this.activeIndex = null
        },
        submitBtn() {
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            }

            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            if (this.params.typeQualification == '') {
                return this.$message.error('请选择您要申领的资料')
            }
            this.api.addCourseApply(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('领取成功！')
                    this.params = {
                        userName: '',
                        phoneNumber: '',
                        courseId: 1,
                        courseType: 16,
                        courseName: '公务员考试',
                        email: '',
                        companyName: '',
                        questionContent: '',
                        typeQualification: '',
                        certificationProject: '',
                        locationEnterprise: '',
                        dateEstablishment: '',
                    }
                } else {
                    this.$message.success(res.msg)
                }
            })
        },

    },

}
</script>
<style lang="scss" scoped>
.civilServant_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #FF881E;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;

    }

    .module1 {
        width: 100%;
        height: 810px;
        background: url('../../assets/img/civilServant/bg1.png') no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1200px;
            height: 440px;

            background-size: 100% 100%;
            margin-top: 50px;
            padding: 0 50px 0 66px;
            box-sizing: border-box;

            .list:nth-child(1) {
                margin-right: 600px;

                .list_name,
                .list_text {
                    text-align: right;
                }
            }

            .list:nth-child(3) {
                margin-bottom: -240px;

                .list_name,
                .list_text {
                    text-align: center;
                }
            }

            .list {
                width: 228px;
                margin-bottom: 150px;

                .list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                }

                .list_text {
                    width: 224px;
                    height: 40px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 18px;
                }
            }
        }
    }

    .module2 {
        padding-bottom: 80px;

        .content {
            margin-top: 50px;
        }

        .icon_img {
            width: 580px;
            height: 540px;
        }

        .main {
            .list:nth-child(1) {
                margin-top: 53px;
                margin-left: -40px;
            }

            .list:nth-child(2) {
                margin-top: 120px;
                margin-left: 20px;
            }

            .list:nth-child(3) {
                margin-top: 90px;
                margin-left: -30px;
            }

            .list {
                width: 539px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
            }
        }

        .btn {
            margin-top: 57px;
        }
    }

    .module3 {
        width: 100%;
        height: 640px;
        background: url('../../assets/img/civilServant/bg3.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 30px;

            .list:nth-child(1) {
                margin-left: 0;
            }

            .list {
                width: 278px;
                height: 375px;
                background: #F4F5F5;
                border-radius: 5px;
                padding-top: 38px;
                margin-left: 28px;

                .list_img {
                    width: 98px;
                    height: 98px;
                }

                .list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 26px;
                }

                .list_text {
                    width: 248px;
                    height: 63px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    margin: 24px 0;
                    text-align: center;

                    .astrict {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }

                .btn {
                    width: 152px;
                    height: 44px;
                    background: #FF881E;
                    border-radius: 5px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #F4F5F5;
                }
            }

        }
    }

    .module4 {
        width: 100%;
        height: 450px;

        .main {
            margin-top: 30px;

            .list:nth-child(1) {
                margin-left: 0;
            }

            .list {
                width: 180px;
                height: 220px;
                background: #FFFFFF;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                padding-bottom: 20px;
                box-sizing: border-box;
                margin-left: 23px;
                padding-top: 20px;

                .list_img {
                    width: 60px;
                    height: 60px;
                }

                .list_name {
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    margin-top: 28px;
                }

                .list_btn {
                    width: 120px;
                    height: 38px;
                    background: #FF881E;
                    border-radius: 19px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #F4F5F5;
                    margin-top: 25px;
                }
            }
        }
    }

    .module5 {
        width: 100%;
        height: 728px;
        background: url('../../assets/img/civilServant/bg4.png') no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1200px;
            height: 458px;
            background: #FFFFFF;
            border-radius: 8px;
            margin-top: 30px;
            padding: 38px 78px 26px 52px;
            box-sizing: border-box;

            .main_fl {
                ul {
                    .ul_li {
                        margin-right: 62px;

                        .label {
                            width: 150px;
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            margin-bottom: 34px;
                        }

                        .ul_li_ul {
                            .ul_li_ul_li {
                                width: 150px;
                                height: 43px;
                                border: 1px solid #003C7E;
                                border-radius: 5px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #003C7E;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }

            .main_fr {
                margin-left: 4px;
                padding-top: 18px;

                .title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003C7E;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 38px;
                }

                .formData {
                    .list {
                        width: 430px;
                        height: 48px;
                        background: #FAFAFA;
                        border: 1px solid #DEDEDE;
                        border-radius: 5px;
                        padding-left: 20px;
                        margin-bottom: 20px;

                        .list_img {
                            width: 28px;
                            height: 28px;
                        }

                        input {
                            width: 380px;
                            height: 46px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            padding-left: 16px;
                            background: none;
                        }

                        ::v-deep.el-input {
                            width: 380px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;

                            .el-input__inner {
                                background: #FAFAFA;
                                border: none;
                            }
                        }
                    }

                    .btn {
                        width: 430px;
                        height: 48px;
                        background: #FF881E;
                        border-radius: 5px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .module6 {
        width: 100%;
        height: 702px;
        background: url('../../assets/img/civilServant/bg5.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 48px;
            position: relative;

            .list_img {
                width: 345px;
                height: 345px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -182px;
                margin-left: -172px;
            }

            .list {
                width: 463px;
                height: 200px;
                background: #FAFAFA;
                border-radius: 5px;
                padding-top: 29px;
                margin: 0 65px;
                margin-bottom: 20px;

                .listName {
                    width: 333px;
                    height: 53px;
                    background: url('../../assets/img/civilServant/bg6.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-left: -9px;
                    margin-bottom: 12px;
                }

                .richText {
                    margin-left: 42px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 40px;
                }
            }

            .list_fr {
                .listName {
                    width: 333px;
                    height: 53px;
                    background: url('../../assets/img/civilServant/bg6s.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 139px;
                    margin-bottom: 12px;
                }

                .richText {
                    margin-left: 146px;
                }
            }
        }
    }

    .module7 {
        width: 100%;
        padding-bottom: 80px;

        .main {
            width: 1200px;
            background: #FFFFFF;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            margin-top: 30px;

            .list.list:nth-child(1) {
                width: 190px;
                border-radius: 5px 0 0 0;
            }

            .list.list:nth-child(2) {
                width: 170px;
            }

            .list.list:nth-child(3) {
                width: 209px;
            }

            .list.list:nth-child(4) {
                width: 170px;
            }

            .list.list:nth-child(5) {
                width: 130px;
            }

            .list.list:nth-child(6) {
                width: 332px;
                border-radius: 0 5px 0 0;
                border-right: none;
                padding: 0 30px;
            }

            .list {
                height: 62px;
                background: #003C7E;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                border-right: 1px solid #fff;
            }

            .table_div_list {
                .list {
                    height: 108px;
                    background: none;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border-right: 1px solid #DEDEDE;
                    border-bottom: 1px solid #DEDEDE;
                }
            }
        }

        .btn {
            margin-top: 38px;
        }
    }

    .module8 {
        width: 100%;
        height: 598px;
        background: url('../../assets/img/civilServant/bg7.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 30px;

            .list:hover {
                background: #003C7E !important;

                .head {
                    .list_name {
                        color: white !important;
                    }
                }

                .list_text {
                    color: white !important;
                }
            }

            .list {
                width: 380px;
                height: 331px;
                background: #FFFFFF;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                padding: 25px 20px 0;
                box-sizing: border-box;

                .head {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #DEDEDE;
                    padding-left: 35px;

                    img {
                        width: 98px;
                        height: 98px;
                        margin-right: 46px;
                    }

                    .list_name {
                        font-size: 32px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                    }
                }

                .list_text {
                    width: 304px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin: 0 auto;
                    margin-top: 20px;
                }
            }
        }
    }

    .module9 {
        height: 762px;

        .main {
            width: 1200px;
            height: 560px;
            background: url('../../assets/img/civilServant/bg8.png') no-repeat;
            background-size: 100% 100%;
            margin-top: 30px;
            position: relative;

            .list:nth-child(1) {
                width: 422px;
                height: 66px;
                background: url('../../assets/img/civilServant/list_bg1.png') no-repeat;
                background-size: 100% 100%;
                padding-left: 33px;
                top: 75px;
                left: 26px;
            }

            .list:nth-child(2) {
                width: 362px;
                height: 95px;
                background: url('../../assets/img/civilServant/list_bg2.png') no-repeat;
                background-size: 100% 100%;
                padding-left: 77px;
                top: 82px;
                right: 23px;

                .list_text {
                    width: 234px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }

            .list:nth-child(3) {
                width: 363px;
                height: 95px;
                background: url('../../assets/img/civilServant/list_bg3.png') no-repeat;
                background-size: 100% 100%;
                padding-left: 39px;
                top: 255px;


                .list_text {
                    width: 234px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }

            .list:nth-child(4) {
                width: 362px;
                height: 67px;
                background: url('../../assets/img/civilServant/list_bg4.png') no-repeat;
                background-size: 100% 100%;
                padding-left: 33px;
                top: 254px;
                right: 0;
                padding-left: 77px;
            }

            .list {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                position: absolute;
            }
        }
    }

    .module10 {
        width: 100%;
        height: 650px;
        background: url('../../assets/img/civilServant/bg9.png') no-repeat;
        background-size: 100% 100%;


        .main {
            width: 1200px;
            background: #FFFFFF;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 37px 40px 11px;
            box-sizing: border-box;
            margin-top: 30px;

            .list {
                width: 540px;
                height: 60px;
                background: #E2F0FB;
                border-radius: 5px;
                margin-bottom: 30px;

                img {
                    width: 26px;
                    height: 26px;
                    margin-left: 15px;
                }

                .list_text {
                    width: 324px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 18px;
                }

                .btn {
                    width: 126px;
                    height: 40px;
                    background: #FF881E;
                    border-radius: 20px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>